.nice-button {
  border-radius: 25px;
  width: auto;
  // min-width: 80px;
  height: 50px;
  font-size: 14px;
  opacity: 1;

  &.nice-button-small {
    height: 40px;
  }
  &.nice-button-disable {
    opacity: 0.5;
  }
  // style by niceType
  &.nice-button-orange {
    background: linear-gradient(270deg, #e82a63 0%, #ea4c2a 100%) !important;
    color: white !important;
    border: none;
  }
  &.nice-button-blue {
    background: rgba(65, 51, 197, 0.1) !important;
    color: #4133c5 !important;
    border: none;
    font-weight: 500;
  }
  &.nice-button-default {
    background: none !important;
    color: #050d43 !important;
    border: 1px solid #050d43 !important;
  }
  &.nice-button-darkBlue {
    background: #050d43 !important;
    color: #fff !important;
    border: none;
    font-weight: 500;
  }

  // @media screen and (min-width: 768px) {
  //   min-width: 200px;
  //   &.nice-button-small {
  //     min-width: 120px;
  //   }
  // }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;
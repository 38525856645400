.layout {
  .sidebar {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    background-color: #fff;
  }

  .site-layout {
    transition: margin-left 0.3s;

    .site-layout-background {
      background: #fff;
    }

    .app-header {
      width: calc(100% - 200px);
      box-shadow: 3px 2px 3px 0px rgba(0, 0, 0, 0.1);
      transition: width 0.3s;
    }

    .content-section {
      margin-top: 80px;
      overflow: initial;
      min-height: calc(100vh - 80px);
    }

    &.side-nav-collapsed {
      margin-left: 80px;
    }

    @media only screen and (max-width: 1200px) {
      &.side-nav-collapsed {
        margin-left: 0px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .site-layout {
      margin-left: 0;

      .app-header {
        width: 100%;
      }
    }
  }
}

.custom-menu-s {
  height: 100vh;
  position: fixed;
  z-index: 2;
  background-color: #060a43;
}

.custom-menu-s-mobile {
  background-color: #060a43;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .custom-menu-s {
    width: 0;
  }
}

.journeyPopoverBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  z-index: 50;
}

.journeyPopover {
  box-shadow: 6px 5px 50px 50px #000000;
}

.dashboard-layout-onboarding {
  div[data-tour-elem='controls'] {
    justify-content: end;
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;
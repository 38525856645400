.nice-ant-modal {
  color: #808080;
  line-height: 24px;

  .ant-modal-content {
    border-radius: 30px;
    .ant-modal-close {
      position: absolute;
      top: -15px;
      right: 12px;
      .ant-modal-close-x {
        width: 32px;
        height: 32px;
        line-height: 32px;
      }
    }
  }
  .title {
    color: #050d43;
  }
  .divider {
    margin-top: 0;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;
.auth-button {
  width: 150px;
  border-radius: 25px;
}

@media screen and (min-width: 768px) {
  .auth-button {
    width: 200px !important;
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;
.minHeight200 {
  min-height: 200px;
}

.minHeight60 {
  min-height: 60px;
}

.width15pr {
  width: 15%;
}

.width35pr {
  width: 35%;
}

.shortcut {
  margin: 0 !important; /* !important to override default antd styles */
  min-height: 60px;
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;
.viewProposal {
  width: 100%;
  overflow-x: hidden;

  .viewProposalContainer {
    width: 95%;
    max-width: 1300px;
    margin: 0 auto;
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;
.tell-us-questions-container {
  color: #333;

  .answer-radio-group {
    .ant-radio-button-wrapper {
      background: #e5f0ff;
      padding: 5px;
      border-radius: 8px;
      border: none;
      color: #050d43;
      height: auto;
      width: 100px;
      text-align: center;
      box-shadow: none;

      &.ant-radio-button-wrapper-checked {
        color: white;
        background-color: #050d43;
      }
      &::before {
        content: none;
      }
    }
  }
  .question-container {
    padding: 20px;
    .option-item {
      padding: 17px 44px;
      border-radius: 8px;
      background-color: #e5f0ff;
      color: #050d43;
      cursor: pointer;

      &.is-selected {
        background-color: #050d43;
        color: white;
      }
    }
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;
.teamOnboardingVideoModal {
  .ant-modal-body {
    width: 564px;
    height: 354px;

    background-color: #606284;
    padding: 7px;
    border-radius: 30px;

    .ant-modal-close {
      right: -30px;
    }

    @media screen and (max-width: 600px) {
      width: 100%;

      .ant-modal-close {
        right: -10px;
      }

      .teamOnboardingVideoModal__video {
        width: 100%;
      }
    }
  }
}

.teamOnboardingVideoModal__video {
  border-radius: 30px;
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;
@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7px);
  }
}

@keyframes loadEvent {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  50% {
    opacity: 20%;
    transform: translateY(-2px);
  }

  100% {
    opacity: 100%;
    transform: translateY(0px);
  }
}

.loadevent {
  animation: 0.4s ease-in-out loadEvent;
}

.signup-page {
  padding-top: 94px;
  padding-bottom: 80px;

  .signup-text {
    font-size: 24px;
    color: black;
    font-family: @font-family;
    letter-spacing: 0.5;
    margin: 0;
    font-weight: 600;
  }

  .steps-content {
    .ant-form-item {
      margin-bottom: 10px;
    }

    .ant-form-item-label {
      font-weight: 500;
      color: #050d43;

      > label {
        height: unset;
      }

      .ant-form-item-required {
        &::before {
          content: none;
        }
      }
    }

    .add-another-btn-container {
      display: flex;
      justify-content: flex-end;

      .practises-text-container {
        span {
          font-size: 18px;
          font-family: @font-family;
          color: #000;
        }
      }
    }

    .item-remove-container {
      display: flex;
      justify-content: space-between;

      .practises-text-container {
        span {
          font-size: 18px;
          font-family: @font-family;
          color: #000;
          font-weight: 600;
        }
      }
    }

    .border-top {
      border-top: 2px solid #ddd;
    }
  }

  .steps-action {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 70px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -8px 10px 0 rgb(0 0 0 / 6%);

    .ant-form-item {
      margin: 0;
    }
  }

  @media screen and (min-width: 768px) {
    .steps-action {
      position: relative;
      box-shadow: none;
      justify-content: end;
    }
  }

  .ant-steps-dot .ant-steps-item-icon {
    height: 0 !important;
    width: 0 !important;
  }

  .ant-steps-item-content {
    display: none;
  }

  .ant-steps-item-finish {
    .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: #050d43 !important;
    }

    .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      background: #050d43 !important;
    }
  }

  .custom-dot {
    height: 27px;
    width: 27px;

    .custom-dot-title {
      display: none;
    }

    .ant-steps-icon-dot {
      background: none !important;
      border: 3px solid #050d43;
      transform: translate(-31%, -35%);
    }

    @media screen and (min-width: 768px) {
      .custom-dot-title {
        display: unset;
        color: rgb(5, 13, 67);
        position: absolute;
        width: 200px;
        left: 50%;
        transform: translate(-50%, 34px);
      }
    }
  }

  .ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
    display: none !important;
  }

  .custom-input,
  .custom-input.ant-input-affix-wrapper,
  .custom-input.ant-select-single,
  .custom-input.ant-select .ant-select-selector {
    min-height: 50px;
    background: white !important;
    border: 1px solid #eeeeee;
    border-radius: 5px;

    .ant-select-selection-item {
      line-height: 50px !important;
    }

    &.ant-input-number,
    .ant-input-number-input {
      min-height: 48px;
    }

    &.ant-date-picker-custom {
      input {
        background-color: white !important;
      }
    }
  }

  .custom-input.ant-select-single {
    border: none;
  }

  .custom-input.ant-select.ant-select-multiple .ant-select-selector {
    height: unset;
    min-height: 50px;
    border: none;
  }

  .ant-select-single.ant-select-lg .ant-select-selection-search-input {
    height: 50px !important;
  }

  .ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-placeholder {
    line-height: 50px !important;
  }

  .auth-upload-avatar {
    .ant-upload.ant-upload-select-picture-card {
      border-radius: 10px;
      width: 150px;
      height: 150px;
    }
  }

  .custom-input.ant-select .ant-select-selection-overflow {
    .ant-select-selection-item {
      line-height: 32px !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .signup-page {
    padding-top: 25px;
    padding-bottom: 40px;
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;